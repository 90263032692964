var flight = require('flight');
var _ = require('lodash');

var Slideshow = flight.component(function() {
    this.defaultAttrs({
        previousButtonSelector: '.slideshow__button-previous',
        nextButtonSelector: '.slideshow__button-next',
        listSelector: '.slideshow__list',
        itemSelector: '.slideshow__item',
        activeItemSelector: '.slideshow__item_active',
        progressBarSelector: '.slideshow__progressbar'
    });

    this.after('initialize', function() {
        var animationInProgress = false;
        var self = this;

        self.initialOffset = this.select('listSelector').position().left;

        NextButton.attachTo(this.select('nextButtonSelector'));
        PrevButton.attachTo(this.select('previousButtonSelector'));

        this.select('itemSelector').on('click', function(event) {
            self.trigger('imageChangeRequested', {'item': $(this)});
        });

        self.$node.find('.slideshow__progress').on('click', function(event) {
            var index = self.$node.find('.slideshow__progress').index(this);
            $next = $(self.select('itemSelector').get(index));
            self.trigger('imageChangeRequested', {item: $next});
        });

        // broadcast to everyone that init is done
        setTimeout(function() {
            var initialItem = self.select('activeItemSelector');
            self.trigger('slideshowInitDone', {
                'src': initialItem.find('img').attr('src'),
                'caption': initialItem.find('.slideshow__caption').html(),
                'item': initialItem
            });
        }, 0);

        this.on('imageChangeRequested', function(event, data) {
            if (animationInProgress) {
                return;
            }

            $current = this.select('activeItemSelector');
            $current.removeClass('slideshow__item_active');

            $next = data.item;
            $next.addClass('slideshow__item_active');

            var offset = $next.position().left;
            var oldPos = self.select('listSelector').position().left;
            var newPos = self.initialOffset + (offset * -1);
            var duration = Math.abs(oldPos - newPos);

            this.trigger('imageChangeInProgress', {
                from: $current,
                to: $next,
                duration: duration,
                src: $next.find('img').attr('src'),
                caption: $next.find('.slideshow__caption').html(),
            });

            this.select('listSelector').animate({'left': newPos}, { duration: duration, complete: function() {
                this.trigger('imageChangeDone');
            }.bind(this)});
        });

        this.on('previousImageRequested', function() {
            this.trigger('imageChangeRequested', { 'item' : this.select('activeItemSelector').prev() });
        });

        this.on('nextImageRequested', function() {
            this.trigger('imageChangeRequested', { 'item': this.select('activeItemSelector').next() });
        });

        // update the progress status
        this.on('imageChangeInProgress', function(event, data) {
            animationInProgress = true;
        });

        this.on('imageChangeDone', function() {
            animationInProgress = false;
        });

        // update the progress bar
        this.on('imageChangeInProgress', function(event, data) {
            var index = this.select('itemSelector').index(data.to);
            var $progressBar = this.select('progressBarSelector');

            $progressBar.find('.slideshow__progress_active').removeClass('slideshow__progress_active');

            var $next = $($progressBar.find('.slideshow__progress').get(index));
            $next.addClass('slideshow__progress_active');
        });
    });
});

var NextButton = flight.component(function() {

    this.after('initialize', function() {
        this.eventPool = this.$node.parent();

        this.on('click', 'nextImageRequested');

        this.on(this.eventPool, 'imageChangeInProgress', function(event, data) {

            if (data.to.next().length < 1) {
                this.$node.css('display', 'none');
            } else {
                this.$node.css('display', 'block');
            }

            var oldPos = parseFloat(this.$node.css('left'));
            var newPos = oldPos - (data.from.width() - data.to.width());

            this.$node.animate({'left': newPos }, { duration: data.duration });
        });

        this.on(this.eventPool, 'slideshowInitDone', function(event, data) {
            setTimeout(function() {
                var firstItemWidth = data.item.width();

                var oldPos = parseFloat(this.$node.css('left'));
                var newPos = oldPos +  firstItemWidth + 40;

                this.$node.animate({'left': newPos, 'opacity': 1 }, { duration: data.duration });
            }.bind(this), 500);

        });
    });

});

var PrevButton = flight.component(function() {

    this.after('initialize', function() {
        this.eventPool = this.$node.parent();

        this.on('click', 'previousImageRequested');

        this.on(this.eventPool, 'imageChangeInProgress', function(event, data) {
            if (data.to.prev().length < 1) {
                this.$node.css('display', 'none');
            } else {
                this.$node.css('display', 'block');
            }
        });
    });
});

module.exports = Slideshow;