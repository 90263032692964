var _ = require('lodash');
var flight = require('flight');

$('.apply-clientside-blocks').each(function(i, element) {
    var $element = $(element);
    $element.removeClass('apply-clientside-blocks');

    var classes = $element.attr('class').split(' ');
    _.forEach(classes, function(blockName) {
        if (blockName.indexOf('_') !== -1) {
            return;
        }

        var block = require('blocks/' + blockName);
        if (block.attachTo !== undefined) {
            block.attachTo($element);
        }
    });
});