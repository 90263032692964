module.exports.attachTo = function($element) {
    var $form = $element.find('.contact__form');

    $form.on('submit', function(event) {
        event.preventDefault();

        var data = {};

        data.name = $form.find('[name="contact-name"]').val();
        data.email = $form.find('[name="contact-email"]').val();
        data.message = $form.find('[name="contact-message"]').val();

        $.post('scripts/contact/contact.php', data, function(data, textStatus, jqXHR) {
            var response;

            if (jqXHR.status == 200) {
                response = '.form__response_success';
            } else {
                response = '.form__response_error';
            }

            $form.find('.form__submit').fadeOut(function() {
                $form.find(response).css({'display': 'block'});
            });
        });
    });
};