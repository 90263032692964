module.exports.attachTo = function($element) {
    $(document).find('.navigation__label').on('click', function() {
        $element.animate({'height': $(window).height()}, {'duration': 700});
    });

    $element.on('click', function() {
        $element.css('height', 0);
    });

    $element.find('.navigation__item-link').on('click', function(event) {
        var $item = $(this).parent();

        if ($item.find('.navigation__sub').length > 0 && $(window).width() <= 360) {
            event.preventDefault();
            event.stopPropagation();

            $element.find('.navigation__sub').slideUp();
            $item.find('.navigation__sub').slideDown();
        }
    });
};