var flight = require('flight');
var _ = require('lodash');

var SlideshowView = flight.component(function() {
    this.after('initialize', function() {
        var self = this;

        function replaceImage(thumbSrc, caption) {
            var originalSrc = thumbSrc.replace('mobile', 'desktop');
            self.$node.find('.slideshow-view__image').attr('src', originalSrc);
            self.$node.find('.slideshow-view__caption').html(caption);
        }

        this.on(document, 'slideshowInitDone', function(event, data) {
            replaceImage(data.src, data.caption || '');
        });

        this.on(document, 'imageChangeInProgress', function(event, data) {
            replaceImage(data.src, data.caption || '');
        });

    });
});

module.exports = SlideshowView;