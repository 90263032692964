var flight = require('flight');
var UAParser = require('ua-parser-js');
var soundManager = require('blocks/sound-manager');

var CD = module.exports = flight.component(function() {

    this.defaultAttrs({
        trackSel: '.cd__track',
        playButtonSel: '.cd__button-play',
        nextButtonSel: '.cd__button-next',
        previousButtonSel: '.cd__button-prev',
        muteButtonSel: '.cd__button-mute'
    });

    this.after('initialize', function() {
        Track.attachTo(this.select('trackSel'), { eventPool: this.$node });

        PreviousButton.attachTo(this.select('previousButtonSel'));
        PlayButton.attachTo(this.select('playButtonSel'));
        NextButton.attachTo(this.select('nextButtonSel'));

        MuteButton.attachTo(this.select('muteButtonSel'));

        soundManager.onready(function() {
            this.trigger('soundManagerIsReady');
        }.bind(this));

        soundManager.ontimeout(function() {
            this.trigger('soundManagerCantLoad');
            window.alert('Die Musik kann nicht abgespielt werden, weil Ihr System keine Audio-Wiedergabe unterstütz. Bitte verwenden Sie einen neueren Internet-Browser oder schalten Sie Flash-Player ein.');
        }.bind(this));
    });

});

var Track = flight.component(function() {

    this.defaultAttrs({
        currentTrackClass: 'cd__track_open',
        trackSel: '.cd__track'
    });

    this.after('initialize', function() {

        this.ePool = this.$node.parents('.cd');

        this.on('click', function() {
            this.trigger('stopRequested');
            this.trigger('trackChangeRequested', this.$node);
            this.trigger('playRequested');
        });

        this.on(this.ePool, 'trackChangeRequested', function(event, newTrack) {
            if (newTrack === undefined) {
                return;
            }

            if (this.$node.is(newTrack)) {

                this.$node.addClass(this.attr.currentTrackClass);

                this.bindCurrentTrackListeners();
                this.trigger('trackChanged', newTrack);

            } else {
                this.$node.removeClass(this.attr.currentTrackClass);
            }
        });

        this.on(this.ePool, 'soundManagerIsReady', function(event) {
            this.$node.data('sound', soundManager.createSound({ 'url': this.$node.data('track-src') }));
            this.bindCurrentTrackListeners();
        });
    });

    this.bindCurrentTrackListeners = function() {
        if (this.$node.hasClass(this.attr.currentTrackClass) === false) {
            return; // this is not current track
        }

        var sound = this.$node.data('sound');

        this.off(this.ePool, '.curTrack'); // unbind previous listeners

        this.on(this.ePool, 'playRequested.curTrack', function(event) {
            if (!sound) return;

            if (sound.paused) {
                sound.resume();

                this.trigger('startedPlaying');
            } else {
                sound.play({ onfinish: function() {
                    this.trigger('nextTrackRequested');
                }.bind(this)});

                this.trigger('startedPlaying');
            }
        });

        this.on(this.ePool, 'pauseRequested.curTrack', function(event) {
            if (!sound) return;

            sound.pause();
            this.trigger('paused');
        });

        this.on(this.ePool, 'stopRequested.curTrack', function(event) {
            if (!sound) return;

            sound.stop();
            this.trigger('stopped');
        });

        this.on(this.ePool, 'nextTrackRequested.curTrack', function(event) {
            this.trigger('stopRequested');

            var node = this.$node.next(this.attr.trackSel);
            if (node.length < 1) return;

            this.trigger('trackChangeRequested', node);

            this.trigger('playRequested');
        });

        this.on(this.ePool, 'previousTrackRequested.curTrack', function(event) {
            this.trigger('stopRequested');

            var node = this.$node.prev(this.attr.trackSel);
            if (node.length < 1) return;

            this.trigger('trackChangeRequested', node);

            this.trigger('playRequested');
        });
    };

});

var PlayButton = flight.component(function() {

    this.after('initialize', function() {

        this.ePool = this.$node.parents('.cd');

        this.on(this.ePool, 'soundManagerCantLoad', function() {
            this.$node.hide();
        });

        this.on('click', function() {
            if (this.$node.hasClass('cd__button-play_playing')) {
                this.trigger('pauseRequested');
            } else {
                this.trigger('playRequested');
            }
        });

        this.on(this.ePool, 'startedPlaying', function(event) {
            this.$node.addClass('cd__button-play_playing');
        });

        this.on(this.ePool, 'stopped paused', function(event) {
            this.$node.removeClass('cd__button-play_playing');
        });

    });
});

var NextButton = flight.component(function() {

    this.defaultAttrs({
        trackSel: '.cd__track'
    });

    this.after('initialize', function() {
        this.ePool = this.$node.parents('.cd');

        this.on(this.ePool, 'soundManagerCantLoad', function() {
            this.$node.hide();
        });

        this.on('click', function() {
            if (this.$node.hasClass('cd__button-next_disabled')) return;
            this.trigger('nextTrackRequested');
        });

        this.on('mouseover', function() {
            if (!this.$node.hasClass('cd__button-next_disabled')) {
                this.$node.addClass('cd__button-next_hover');
            }
        });

        this.on('mouseout', function() {
            this.$node.removeClass('cd__button-next_hover');
        });

        this.on(this.ePool, 'trackChanged', function(event, newTrack) {
            if($(newTrack).next(this.attr.trackSel).length < 1) {
                this.$node.addClass('cd__button-next_disabled');
            } else {
                this.$node.removeClass('cd__button-next_disabled');
            }
        });
    });

});

var PreviousButton = flight.component(function() {

    this.defaultAttrs({
        trackSel: '.cd__track'
    });

    this.after('initialize', function() {
        this.ePool = this.$node.parents('.cd');

        this.on(this.ePool, 'soundManagerCantLoad', function() {
            this.$node.hide();
        });

        this.on('click', function() {
            if (this.$node.hasClass('cd__button-prev_disabled')) return;
            this.trigger('previousTrackRequested');
        });

        this.on('mouseover', function() {
            if (!this.$node.hasClass('cd__button-prev_disabled')) {
                this.$node.addClass('cd__button-prev_hover');
            }
        });

        this.on('mouseout', function() {
            this.$node.removeClass('cd__button-prev_hover');
        });

        this.on(this.ePool, 'trackChanged', function(event, newTrack) {
            if($(newTrack).prev(this.attr.trackSel).length < 1) {
                this.$node.addClass('cd__button-prev_disabled');
            } else {
                this.$node.removeClass('cd__button-prev_disabled');
            }
        });
    });

});

var MuteButton = flight.component(function() {

    this.after('initialize', function() {
        this.ePool = this.$node.parents('.cd');

        this.on(this.ePool, 'soundManagerCantLoad', function() {
            this.$node.hide();
        });

        this.on('mouseover', function() {
            if(this.$node.hasClass('cd__button-mute_muted')) {
                this.$node.addClass('cd__button-mute_muted-hover');
            } else {
                this.$node.addClass('cd__button-mute_hover');
            }
        });

        this.on('mouseout', function() {
            this.$node.removeClass('cd__button-mute_hover');
            this.$node.removeClass('cd__button-mute_muted-hover');
        });

        this.on('click', function() {
            if(this.$node.hasClass('cd__button-mute_muted')) {
                soundManager.unmute();
                this.$node.removeClass('cd__button-mute_muted');
            } else {
                soundManager.mute();
                this.$node.addClass('cd__button-mute_muted');
            }

            this.$node.removeClass('cd__button-mute_hover');
            this.$node.removeClass('cd__button-mute_muted-hover');
        });

        if((new UAParser()).getBrowser().name == 'Mobile Safari') {
            // muting doesn't work on mobile safari
            this.$node.css({'display': 'none'});
        }
    });

});

module.exports = CD;